import "./ui/marquee";

import * as Aos from 'aos';
import $ from "jquery";

import { onLoad } from "../../../common/ts/utils";

let editing = false;
editing = ((new URL(location.href).searchParams.get("edit"))) ? true : false;

console.log("editing", editing);

if (!editing) {
    Aos.init();
}
else {
    const aosElems = document.querySelectorAll('[data-aos][data-aos]');
    aosElems.forEach((elm) => {
        const element = elm as HTMLElement;
        element.style.opacity = '1';
        element.style.transform = 'none';
    })
}

document.addEventListener("scroll", () => {
    const header = document.getElementById("header");
    if (header) {
        if (window.scrollY > 30) {
            header.classList.add("scrolled");
        } else if (window.scrollY < 70) {
            header.classList.remove("scrolled");
        }
    }
});

onLoad(() => {

    $('#content a').each(function () {
        const $link = $(this);
        const url = $link.attr('href');

        if (url?.charAt(0) == '#') {
            $(url).addClass('anchor')
            //$link;
        }

    });


});